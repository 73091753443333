import {Injectable} from '@angular/core';
import Gleap from "gleap";
import {environment} from "../../../environments/environment";
import {User} from "../../core/models/user";
import {Membership} from "../../core/models/package";
import {Event} from "../../core/models/event";

@Injectable({
    providedIn: 'root'
})
export class GleapService {

    private isInitialised = false;
    private customData: Record<string, any> = {};

    constructor() {
    }

    init() {
        if (!environment.production) {
            Gleap.setEnvironment("staging");
        }
        Gleap.initialize(environment.gleap.key);
        this.isInitialised = true;
    }

    identifyUser(user: User) {
        if (!this.isInitialised || Gleap.isUserIdentified()) {
            return;
        }
        this.customData = {
            appsumo: user.appsumo
        };

        Gleap.identify(user.id.toString(), {
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
            phone: user.phone,
            createdAt: user.registeredAt?.toDate(),
            customData: this.customData
        });
    }

    updateUserPlanData(event: Event) {
        if (!this.isInitialised || !Gleap.isUserIdentified()) {
            return;
        }
        const currentUserData = Gleap.getIdentity();
        const currentMembership = event.payment.membership;
        const isCustomer = currentUserData.customData.isCustomer || (currentMembership !== Membership.FREE && currentMembership !== Membership.TRIAL)
        const hasSubscription = currentUserData.customData.hasSubscription || (isCustomer && currentMembership !== Membership.SOLO);
        this.customData = {
            ...this.customData,
            isCustomer,
            hasSubscription
        };
        let plan = currentUserData.plan;
        if (currentMembership !== Membership.TRIAL || !plan) {
            plan = this.getStringFromMembership(currentMembership);
        }
        Gleap.updateContact({
            plan,
            customData: this.customData
        });
    }

    changeVisibility(visible: boolean) {
        Gleap.showFeedbackButton(visible);
    }

    logoutUser() {
        if (this.isInitialised) {
            Gleap.clearIdentity();
        }
    }

    private getStringFromMembership(membership: number) {
        switch (membership) {
            case Membership.TRIAL:
                return 'Trial';
            case Membership.SOLO:
                return 'Solo';
            case Membership.PREMIUM:
                return 'Premium';
            case Membership.BUSINESS:
                return 'Business';
            case Membership.BUSINESS_PLUS:
                return 'Business plus';
            case Membership.ENTERPRISE:
                return 'Enterprise';
            case Membership.LIFETIME:
                return 'Lifetime';
            case Membership.APP_SUMO_LIFETIME:
                return 'AppSumo Lifetime';
            case Membership.APP_SUMO_2_LIFETIME:
                return 'AppSumo Lifetime 2';
            case Membership.APP_SUMO_3_LIFETIME:
                return 'AppSumo Lifetime 3';
            case Membership.APP_SUMO_4_LIFETIME:
                return 'AppSumo Lifetime 4';
            case Membership.APP_SUMO_5_LIFETIME:
                return 'AppSumo Lifetime 5';
            case Membership.APP_SUMO_6_LIFETIME:
                return 'AppSumo Lifetime 6';
            case Membership.APP_SUMO_7_LIFETIME:
                return 'AppSumo Lifetime 7';
            default:
                return 'Free';
        }
    }
}
