export const environment = {
	production: true,
	local: false,

	web: {
		baseUrl: 'https://eventee.com/',
	},

	socket: {
		socketUrl: 'https://socket.eventee.com',
		questions_namespace: '/questions',
		polls_namespace: '/polls',
		reconnect: 3000
	},

	appApi: {
		baseUrl: `https://api.eventee.com`,
		analyticsUrl: `https://analytics.eventee.com`
	},

	scheduleConfig: {
		hourHeight: 250,
		startHour: 10,
		endHour: 20
	},

	socials: {
		facebookId: '1669052363314014',
		linkedInId: '77nfjh5nukrr1h',
        eventbriteId: 'FQRB5QVDG77LEDOGD3',
        appleId: 'com.eventee.admin',
		mailChimpId: '876892593886',
		zoomId: 'uGiJW4hISbWvFQFKtg31Q'
    },

    fastspring: {
        accessKey: 'LESD7BQYTHCROXTQQDXFCG',
        storefront: 'eventee.onfastspring.com/popup-production'
    },

    paddle: {
        sellerId: '172561'
    },

    bugsnag: {
        stage: 'production',
        key: 'eab28ca5c34973bbd40a12c1cbe9e123'
    },

	productFruits: {
		key: '7NAisx8zGe3Vms2w'
	},

	gleap: {
		key: '77asnFy92CoEI6xRwKPEpD8lFg5QWETA'
	},

	googleMaps: {
		key: 'AIzaSyBukLF5MP5zuiGZWVyzfOe-x_G8rhxKg_k',
		mapId: '39ddec7e7c622675'
	},

    recaptchaKey: '6LcZ__cdAAAAAD-IK6_kWxtSb0Aujz9x1nnUbboo',

    growthBook: {
		url: 'https://cdn.growthbook.io/api/features/prod_LY8ZIs8rFUvwzSrBUQB5V0aFJehk10wcaoBjYmHWAxw',
		key: 'gYAEDuNJeR+KA3toQgSLlA=='
	}
};
